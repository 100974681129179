/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { NodeFilters, NodeSortableFields } from '@/client';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';
import { defineTypes } from '../filters';

export const NodesFilterTypes = defineTypes<NodeFilters>({
  name: 'string',
  hostname: 'string',
  tags: 'string',
  operatingSystem: 'string',
  isOperating: 'boolean',
  isArchived: 'boolean',
  appliedNodeConfigId: 'string',
});

export function useNodesQuery(
  tenantId: string,
  filters: NodeFilters,
  sort: NodeSortableFields,
  settings: PaginationParams,
) {
  return useQuery({
    queryKey: ['nodes', tenantId, settings.number, settings.size, sort, filters],
    queryFn: async () => {
      return mgmtPlaneAPIClient.nodes.getNodes({
        tenantId,
        page: settings.number,
        pageSize: settings.size,
        filters: { isOperating: true, ...filters },
        sort,
      });
    },
    staleTime: 5 * 1000,
    enabled: !!tenantId,
  });
}
