/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useState } from 'react';
import { ConfigRevision, Node, NodeConfig, NodeConfigFilters, NodeConfigSortableFields } from '@/client';
import { PaginationParams } from '@/client/mgmtPlane';
import { Card } from '@/primitives/Card';
import { useNodeConfigsQuery } from '@/utils/hooks/useNodeConfigsQuery';
import { fromSorter } from '@/utils/sort';
import { NodeConfigsList, NodeConfigsListProps } from '../NodeConfigsList';

export type SelectNodeConfigCardProps = {
  node: Node;
  selectedNodeConfig?: NodeConfig;
  onNodeConfigSelect: (nodeConfig?: NodeConfig) => void;
};

export function SelectNodeConfigCard({ node, selectedNodeConfig, onNodeConfigSelect }: SelectNodeConfigCardProps) {
  const [nodeConfigFilters, setNodeConfigFilters] = useState<Partial<NodeConfigFilters>>({ os: node.operatingSystem });
  const [nodeConfigSort, setNodeConfigSort] = useState<NodeConfigSortableFields>([]);
  const [nodeConfigPage, setNodeConfigPage] = useState<PaginationParams>({ number: 1, size: 10 });
  const configsQuery = useNodeConfigsQuery({
    tenantId: node.tenantId,
    filters: nodeConfigFilters,
    sort: nodeConfigSort,
    settings: nodeConfigPage,
  });

  const updateQueryParams: NodeConfigsListProps['onFilterAndSortChange'] = ({ filters, sort, paginationParams }) => {
    onNodeConfigSelect(undefined);
    if (filters) setNodeConfigFilters(filters);
    if (paginationParams) setNodeConfigPage(paginationParams);
    if (sort) {
      const sortFields = fromSorter<NodeConfigSortableFields, NodeConfig>(sort);
      setNodeConfigSort(sortFields);
    }
  };

  const getNodeConfigPath = (nodeConfig: NodeConfig) => `/tenants/${node.tenantId}/node-configs/${nodeConfig.id}`;
  const getNodeConfigurationRevisionPath = (nodeConfigRevision: ConfigRevision) =>
    `/tenants/${node.tenantId}/node-configs/${nodeConfigRevision.nodeConfigId}/revisions/${nodeConfigRevision.revisionNumber}`;

  return (
    <Card dataTestId="config-wizard-select-node-config" padding="sm" bordered>
      <NodeConfigsList
        tags={[]} // TODO: adds tags MP-1656
        onFilterAndSortChange={updateQueryParams}
        nodeConfigsQueryResponse={configsQuery}
        getNodeConfigPath={getNodeConfigPath}
        getNodeConfigurationRevisionPath={getNodeConfigurationRevisionPath}
        onNodeConfigSelect={onNodeConfigSelect}
        defaultNodeConfig={selectedNodeConfig}
      />
    </Card>
  );
}
