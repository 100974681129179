/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { PlusOutlined, StopOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { User, UserFilters } from '@/client';
import { Container } from '@/primitives/Container';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { MemberActionDropdown } from './MemberActionDropdown';
import { Role } from './MemberTable';
import { Button } from '@/primitives/Button';
import { Segmented, SegmentedValue } from '@/primitives/Segmented';
import { PageHeader } from '../PageHeader';
import { Space } from '@/primitives/Space';
import { GetUserListOptions } from '@/utils/hooks/getUserListOptions';
import { CountLabel } from '../CountLabel';
import { RelativeTime } from '../RelativeTime';
import { UpdateParams } from '@/utils/params';
import { PaginatedTable } from '../PaginatedTable';

enum UserState {
  REMOVED = 'removed',
  ACTIVE = 'active',
}

export type MembersListPageParams = {
  currentUser: User;
  activeOptions: GetUserListOptions;
  removedOptions: GetUserListOptions;
  filters: UserFilters;
  onInviteClick: () => void;
  onUpdateClick: (user: User) => void;
  onChangeRole: (user: User, role: Role) => void;
  onChangeRemoved: (user: User, removed: boolean) => void;
  onParamsChange: UpdateParams<User, Partial<UserFilters>>;
};

export function MembersListPage({
  currentUser,
  activeOptions,
  removedOptions,
  filters,
  onInviteClick,
  onUpdateClick,
  onChangeRole,
  onChangeRemoved,
  onParamsChange,
}: MembersListPageParams) {
  const { t } = useMimicTranslation('members');
  const activeMembersQuery = useQuery(activeOptions);
  const removedMembersQuery = useQuery(removedOptions);

  const onChangeActiveFilter = (value: SegmentedValue) => {
    if (value === UserState.ACTIVE) {
      onParamsChange({ filters: { removed: false } });
    } else if (value === UserState.REMOVED) {
      onParamsChange({ filters: { removed: true } });
    }
  };

  const membersQuery = filters.removed ? removedMembersQuery : activeMembersQuery;

  const activeColumns: ColumnsType<User> = [
    {
      title: t('member'),
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('columns.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role: User['role']) => t(`roles.${role}`),
    },
    {
      title: t('columns.dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (dateCreated: User['dateCreated']) => <RelativeTime date={dateCreated} />,
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space size="md">
            <MemberActionDropdown
              user={record}
              currentUser={currentUser}
              onUpdateClick={onUpdateClick}
              onChangeRole={onChangeRole}
              onChangeRemoved={onChangeRemoved}
            />
          </Space>
        );
      },
    },
  ];

  const removedColumns: ColumnsType<User> = [
    {
      title: t('member'),
      dataIndex: 'displayName',
      key: 'displayName',
      render: (_, record) => {
        if (record.removed) {
          return (
            <Space>
              <StopOutlined />
              {record.displayName}
            </Space>
          );
        }
        return record.displayName;
      },
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('columns.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role: User['role']) => t(`roles.${role}`),
    },
    {
      title: t('columns.dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (dateCreated: User['dateCreated']) => <RelativeTime date={dateCreated} />,
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      align: 'right',
      render: (_, record) => {
        return (
          <Button type="default" onClick={() => onChangeRemoved(record, false)} dataTestId="restore-button">
            {t('actions.restore')}
          </Button>
        );
      },
    },
  ];

  const activeTotal = activeMembersQuery.data?.meta.page.totalItems || 0;
  const removedTotal = removedMembersQuery.data?.meta.page.totalItems || 0;

  const activeFilterValue = filters.removed ? UserState.REMOVED : UserState.ACTIVE;

  const columns = filters.removed ? removedColumns : activeColumns;

  return (
    <Container>
      <PageHeader title={t('title')} text={t('subtitle')}>
        <Button type="primary" onClick={onInviteClick} dataTestId="invite-new-member">
          <PlusOutlined /> {t('newMember')}
        </Button>
      </PageHeader>
      <Segmented
        dataTestId="removed-switcher"
        defaultValue={UserState.ACTIVE}
        value={activeFilterValue}
        onChange={onChangeActiveFilter}
        options={[
          {
            label: (
              <CountLabel
                text="Active"
                count={activeTotal}
                loading={activeMembersQuery.isPending}
                data-testid="segment-active"
              />
            ),
            value: UserState.ACTIVE,
          },
          {
            label: (
              <CountLabel
                text="Removed"
                count={removedTotal}
                loading={removedMembersQuery.isPending}
                data-testid="segment-removed"
              />
            ),
            value: UserState.REMOVED,
          },
        ]}
      />
      <PaginatedTable
        dark={filters.removed}
        data-testid="member-table"
        rowKey={(tag) => tag.id}
        paginatedResource={membersQuery}
        columns={columns}
        onParamsChange={onParamsChange}
      />
    </Container>
  );
}
