/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CheckCircleFilled, DeploymentUnitOutlined, PlusOutlined } from '@ant-design/icons';
import { UseQueryResult } from '@tanstack/react-query';
import { Avatar } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Node, NodeConfig, NodeFilters, PaginatedNodesResponse, Tag } from '@/client';
import { Badge } from '@/primitives/Badge';
import { Button } from '@/primitives/Button';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { UpdateParams } from '@/utils/params';
import { MimicTranslationFunction, useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import NoData from './Icons/NoData.svg?react';
import MimicHighlighter from './MimicHighlighter';
import { NodeViewPageCard } from './Node/NodeViewPageCard';
import { PageHeader } from './PageHeader';
import { PaginatedTable } from './PaginatedTable';
import { RelativeTime } from './RelativeTime';
import { TagsList } from './TagsList';

export type AssignedNodesProps = {
  nodeConfig: NodeConfig;
  tags: Tag[] | undefined;
  nodesQuery: UseQueryResult<PaginatedNodesResponse>;
  onParamsChange: UpdateParams<Node, Partial<NodeFilters>>;
  getPendingNodesPath: () => string;
  getNodePath: (node: Node) => string;
};

export function AssignedNodes({
  nodeConfig,
  tags,
  onParamsChange,
  nodesQuery,
  getPendingNodesPath,
  getNodePath,
}: AssignedNodesProps) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');

  const columns = getNodeColumns(t, nodeConfig, nodesQuery.data?.meta.filters || {}, tags, getNodePath);

  if (nodesQuery.data?.meta.page.totalItems === 0) {
    return <EmptyMessage getPendingNodesPath={getPendingNodesPath} />;
  }

  return (
    <NodeViewPageCard dataTestId="assigned-nodes">
      <Container style={{ paddingTop: 20 }}>
        <PageHeader title={t('assignedNodes.title')} level={5}>
          <Button type="primary" dataTestId="assign-node-button" style={{ minHeight: '36px' }}>
            <Link to={getPendingNodesPath()} style={{ textDecoration: 'none' }}>
              <PlusOutlined style={{ marginRight: '8px' }} /> {t('assignedNodes.assignConfigToNode')}
            </Link>
          </Button>
        </PageHeader>
        <PaginatedTable
          rowKey="id"
          data-testid="assigned-nodes-table"
          columns={columns}
          paginatedResource={nodesQuery}
          onParamsChange={onParamsChange}
        />
      </Container>
    </NodeViewPageCard>
  );
}

function EmptyMessage({ getPendingNodesPath }: Pick<AssignedNodesProps, 'getPendingNodesPath'>) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');

  return (
    <NodeViewPageCard dataTestId="assigned-nodes">
      <div style={{ padding: '40px' }}>
        <Flex vertical align="center">
          <NoData fontSize="6em" />
          <Text type="subtle">{t('assignedNodes.noNodesAssigned')}</Text>
          <Button type="primary" dataTestId="assign-node-button" style={{ minHeight: '48px' }}>
            <Link to={getPendingNodesPath()} style={{ textDecoration: 'none' }}>
              {t('assignedNodes.assignConfigToNode')}
            </Link>
          </Button>
        </Flex>
      </div>
    </NodeViewPageCard>
  );
}

function getNodeColumns(
  t: MimicTranslationFunction<'nodeConfigurationDetail'>,
  nodeConfig: NodeConfig,
  filters: NodeFilters,
  tagsList: Tag[] | undefined,
  getNodePath: (node: Node) => string,
) {
  const columns: ColumnType<Node>[] = [
    {
      title: <span data-testid="name">{t('assignedNodes.table.header.nodes')}</span>,
      dataIndex: 'name',
      sorter: true,
      filterMode: 'menu',
      render: (name: string, record) => {
        return (
          <Link to={getNodePath(record)} style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }} dataTestId="node-link">
            <Flex gap="xxs">
              <DeploymentUnitOutlined />
              <MimicHighlighter searchText={filters.name} text={name} />
            </Flex>
          </Link>
        );
      },
    },
    {
      title: <span data-testid="tags">{t('assignedNodes.table.header.tags')}</span>,
      dataIndex: 'tags',
      filters: tagsList?.map((tag) => {
        return { text: tag.name, value: tag.name };
      }),
      filterMode: 'menu',
      filterSearch: true,
      width: '30%',
      render: (tags: string[]) => {
        return <TagsList tags={tags} />;
      },
    },
    {
      title: <span data-testid="config-version">{t('assignedNodes.table.header.configVersion')}</span>,
      dataIndex: 'configVersion',
      render: (_, record) => {
        if (
          record.appliedRevisionState?.configRevision?.revisionNumber !== nodeConfig.latestConfigRevision.revisionNumber
        ) {
          return (
            <Badge
              bgColor="#FAC83D"
              color="rgba(25, 25, 25, 0.88)"
              text={`#${record.appliedRevisionState?.configRevision?.revisionNumber}`}
            />
          );
        }
        return <CheckCircleFilled style={{ fontSize: '20px', color: '#5C911C' }} />;
      },
    },
    {
      title: <span data-testid="last-updated">{t('assignedNodes.table.header.dateConfigRevisionApplied')}</span>,
      dataIndex: 'dateConfigRevisionApplied',
      sorter: true,
      key: 'dateConfigRevisionApplied',
      render: (_, node) => {
        if (node.appliedRevisionState?.statusDate) {
          return <RelativeTime date={node.appliedRevisionState?.statusDate} />;
        }
        return '-';
      },
      width: '200px',
    },
    {
      title: <span data-testid="assigned-by">{t('assignedNodes.table.header.assginedBy')}</span>,
      dataIndex: 'assignedBy',
      render: (_, node) => {
        return (
          <Flex gap="xs" align="center">
            <Avatar
              style={{
                color: 'rgba(25, 25, 25, 0.75)',
                backgroundColor: 'rgba(25, 25, 25, 0.20)',
              }}
            >
              {node.appliedRevisionState?.assignedBy?.displayName[0]}
            </Avatar>
            {node.appliedRevisionState?.assignedBy?.displayName}
          </Flex>
        );
      },
      width: '200px',
    },
  ];
  return columns;
}
