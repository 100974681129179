/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Content } from './primitives/Content';
import { Sider } from './primitives/Sider';
import { Layout } from './primitives/Layout';
import { Header } from './primitives/Header';
import { MimicLogo } from './components/MimicLogo';
import { AuthenticatedRoute } from './routes/AuthenticatedRoute';
import { LeftMenu } from './components/menus/LeftMenu';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { Tenant } from './client';
import { TenantSwitcher } from './components/TenantSwitcher/TenantSwitcher';
import { Container } from './primitives/Container';
import { SupportFooter } from './components/SupportFooter';
import { useChannel } from './utils/hooks/useChannel';
import { MimicErrorBoundary } from './components/MimicErrorBoundary';

export function MainLayout() {
  const navigate = useNavigate();
  const { tenantID } = useParams();
  const { pathname } = useLocation();
  const currentUser = useMgmtPlaneStateStore((state) => state.userProfile);
  const setSelectedTenantID = useMgmtPlaneStateStore((state) => state.setSelectedTenantID);
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID) || tenantID;

  useChannel(selectedTenantID);

  useEffect(() => {
    if (!tenantID) return;
    if (!currentUser) return;
    if (currentUser.role !== 'superadmin') return;
    setSelectedTenantID(tenantID);
  }, [currentUser, tenantID, selectedTenantID, setSelectedTenantID]);

  const onSelectTenant = (tenant: Tenant) => {
    if (currentUser?.role !== 'superadmin') return;
    setSelectedTenantID(tenant.id);

    // if current route is /tenant/:tenantID, where tenantID is a uuid, replace the route with the new tenantID
    if (pathname.includes('/tenants/')) {
      const newPath = pathname.replace(/\/tenants\/[a-f0-9-]+/, `/tenants/${tenant.id}`);
      navigate(newPath);
    }
  };

  return (
    <MimicErrorBoundary>
      <AuthenticatedRoute>
        <Layout>
          <Layout hasSider>
            <Sider>
              <Container gap="none" justify="space-between" full>
                <Container.Fixed>
                  <Header>
                    <MimicLogo width="6rem" height="2rem" />
                  </Header>
                  <TenantSwitcher
                    currentUser={currentUser}
                    selectedTenantID={selectedTenantID}
                    onSelectTenant={onSelectTenant}
                  />
                </Container.Fixed>
                <Container.Expand>
                  <LeftMenu
                    tenantID={selectedTenantID!}
                    currentUser={currentUser!}
                    navigate={navigate}
                    currentPath={pathname}
                  />
                </Container.Expand>
              </Container>
            </Sider>
            <Content>
              <Outlet />
              <SupportFooter />
            </Content>
          </Layout>
        </Layout>
      </AuthenticatedRoute>
    </MimicErrorBoundary>
  );
}
