import { Socket } from 'phoenix';
import { useEffect, useRef } from 'react';
import { useMessage } from '@/primitives/message';
import { mgmtPlaneAPIClient } from '../../client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function useWebsocket() {
  const triggering = useRef<boolean>(false);
  const socket = useRef<Socket | undefined>(undefined);
  const userProfile = useMgmtPlaneStateStore((state) => state.userProfile);
  const setSocket = useMgmtPlaneStateStore((state) => state.setSocket);
  const [message] = useMessage();

  useEffect(() => {
    (async () => {
      if (!userProfile?.id) return;
      if (triggering.current) return;
      triggering.current = true;

      const { data: tokenInfo } = await mgmtPlaneAPIClient.auth.generateToken();
      const { token, tenantID } = tokenInfo;

      socket.current = new Socket(`${window.mimicConfig.MIMIC_MGMT_PLANE_API}/api/socket`, {
        params: { token, tenantID },
        heartbeatIntervalMs: 1000,
      });
      setSocket(socket.current);

      socket.current.connect();
      socket.current.onError((err) => {
        // eslint-disable-next-line no-console
        console.warn('Websocket connection error', err);
      });
    })();

    return () => {
      if (!socket.current) return;
      socket.current.disconnect();
    };
  }, [message, socket, userProfile, setSocket]);
}
