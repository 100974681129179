/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Notification } from '@/client';
import { NotificationsListPage } from './NotificationsListPage';
import { ContentContainer } from '@/components/ContentContainer';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { getNotificationListOptions } from '@/utils/hooks/getNotificationListOptions';
import { useQueryParams } from '@/utils/hooks/useQueryParams';

export function NotificationsRoute() {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const { paginationParams, updateQueryParams } = useQueryParams<Notification>();
  const options = getNotificationListOptions(tenantID!, {}, paginationParams);

  return (
    <ContentContainer>
      <NotificationsListPage options={options} onParamsChange={updateQueryParams} />
    </ContentContainer>
  );
}
