/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useParams } from 'react-router-dom';
import { ConfigRevision, Node } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { Flex } from '@/primitives/Flex';
import { NodeConfigurationDetails } from '@/primitives/NodeConfigurationDetails';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function NodeConfigurationDetailsRoute() {
  const { nodeConfigId } = useParams<'nodeConfigId'>();
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  const pathToRevision = (configRevision: ConfigRevision) =>
    `/tenants/${tenantId}/node-configs/${nodeConfigId}/revisions/${configRevision.revisionNumber}`;

  const getNodePath = (node: Node) => `/tenants/${tenantId}/nodes/${node.id}`;
  const getPendingNodesPath = () => `/tenants/${tenantId}/nodes?filters%5BisOperating%5D=false`;

  return (
    <ContentContainer width="100%">
      <Flex vertical gap="sm">
        <NodeConfigurationDetails
          tenantId={tenantId!}
          nodeConfigId={nodeConfigId!}
          pathToRevision={pathToRevision}
          getNodePath={getNodePath}
          getPendingNodesPath={getPendingNodesPath}
        />
      </Flex>
    </ContentContainer>
  );
}
