/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Socket } from 'phoenix';
import { StateCreator } from 'zustand';

export interface WebsocketSlice {
  socket?: Socket;
  setSocket: (socket: Socket | undefined) => void;
}

export const createWebsocketSlice: StateCreator<WebsocketSlice, [], [], WebsocketSlice> = (set) => ({
  socket: undefined,
  setSocket: (socket) => set(() => ({ socket })),
});
