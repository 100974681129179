/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseQueryResult } from '@tanstack/react-query';
import { Node, NodeFilters, PaginatedNodesResponse, Tag } from '@/client/';
import { CountLabel } from '@/components/CountLabel';
import { InstallerDropdown } from '@/components/InstallerDropdown';
import { NodesTable } from '@/components/NodesTable/NodesTable';
import { NodesColumns } from '@/components/NodesTable/getNodesColumns';
import { PageHeader } from '@/components/PageHeader';
import { Container } from '@/primitives/Container';
import { Segmented, SegmentedValue } from '@/primitives/Segmented';
import { UpdateParams } from '@/utils/params';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';

enum NodeFilter {
  CONFIGURED = 'configured',
  PENDING = 'pending',
}

export type NodeListPageProps = {
  tenantID: string;
  tags: Tag[] | undefined;
  onParamsChange: UpdateParams<Node, Partial<NodeFilters>>;
  filters: NodeFilters;
  configuredNodesQuery: UseQueryResult<PaginatedNodesResponse>;
  pendingNodesQuery: UseQueryResult<PaginatedNodesResponse>;
  getNodeConfigurationPath: (nodeConfigId: string) => string;
  onAssignConfiguration: (node: Node) => void;
};

const pendingColumns: NodesColumns[] = [
  'hostname',
  'lastSeen',
  'operatingSystem',
  'dateCreated',
  'assignConfiguration',
];

const configuredColumns: NodesColumns[] = [
  'hostname',
  'tags',
  'appliedOperationalState',
  'connectivityState',
  'operatingSystem',
  'lastSeen',
  'appliedConfiguration',
];

export function NodeListPage({
  tenantID,
  tags,
  onParamsChange,
  filters,
  configuredNodesQuery,
  pendingNodesQuery,
  getNodeConfigurationPath,
  onAssignConfiguration,
}: NodeListPageProps) {
  const { t } = useMimicTranslation('nodes');

  const onChangeActiveFilter = (value: SegmentedValue) => {
    if (value === NodeFilter.CONFIGURED) {
      onParamsChange({ filters: { isOperating: true }, paginationParams: { number: 1 } });
    } else if (value === NodeFilter.PENDING) {
      onParamsChange({ filters: { isOperating: false }, paginationParams: { number: 1 } });
    }
  };

  const activeFilterValue = filters.isOperating === false ? NodeFilter.PENDING : NodeFilter.CONFIGURED;
  const configuredCount = configuredNodesQuery.data?.meta.page.totalItems;
  const pendingCount = pendingNodesQuery.data?.meta.page.totalItems;

  const nodesQuery = filters.isOperating ? configuredNodesQuery : pendingNodesQuery;
  const visibleColumns = filters.isOperating ? configuredColumns : pendingColumns;

  return (
    <Container>
      <PageHeader title={t('title')} level={2}>
        <InstallerDropdown
          action={(os) => {
            // eslint-disable-next-line no-alert
            alert(`TODO: download ${os} installer`);
          }}
        />
      </PageHeader>
      <Segmented
        dataTestId="pending-switcher"
        defaultValue={NodeFilter.CONFIGURED}
        value={activeFilterValue}
        onChange={onChangeActiveFilter}
        options={[
          {
            label: (
              <CountLabel
                text={t('configured')}
                count={configuredCount!}
                loading={configuredNodesQuery.isPending}
                data-testid="segment-confirmed"
              />
            ),
            value: NodeFilter.CONFIGURED,
          },
          {
            label: (
              <CountLabel
                text={t('pending')}
                count={pendingCount!}
                loading={pendingNodesQuery.isPending}
                data-testid="segment-pending"
              />
            ),
            value: NodeFilter.PENDING,
          },
        ]}
      />
      <NodesTable
        nodesQuery={nodesQuery}
        onParamsChange={onParamsChange}
        columns={visibleColumns}
        tags={tags}
        tenantID={tenantID}
        onAssignConfiguration={onAssignConfiguration}
        getNodeConfigurationPath={getNodeConfigurationPath}
      />
    </Container>
  );
}
