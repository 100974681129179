/*
 * Copyright Mimic Networks, Inc. 2024.
 */

export const en = {
  translation: {
    global: {
      hello: 'Hello',
      ops: 'Ops, something went wrong',
      dashboard: 'Dashboard',
      signIn: 'Sign In',
      signOut: 'Sign Out',
      copyright: 'Copyright',
      next: 'Next',
      back: 'Back',
      finish: 'Finish',
      copy: 'Copy',
      copied: 'Copied',
      example: 'Example',
      loading: 'Loading',
      regenerate: 'Regenerate',
      notAvailable: 'Not available',
      apiRequestFailedTitle: 'API Request failed',
      apiRequestFailedDescription: '{{method}} request to {{url}} failed: {{error}}',
      forms: {
        required: 'This is required',
        invalid: 'Invalid',
      },
    },
    assignConfigOverlay: {
      title: 'Select a configuration to assign to node',
      cancel: 'Cancel',
      assign: 'Assign',
    },
    configWizardOverlay: {
      selectConfiguration: 'Select configuration',
      selectRevision: 'Select revision',
      node: 'Node',
      configuration: 'Configuration',
      configurations: 'Configurations',
      revision: 'Revision',
      revisions: 'Revisions',
      assign: 'Assign',
      cancel: 'Cancel',
      change: 'Change',
      next: 'Next',
      previous: 'Previous',
      reviewConfigurations: 'Review configuration assignment',
    },
    footer: {
      copyright: '© 2024 Mimic Networks, Inc.',
      contactUs: 'Need help? Contact us',
    },
    installer: {
      download_installer: 'Download Installer',
      installer: 'installer',
      done_button: 'Done',
      steps: {
        default: {
          step1_pre_action: 'Download the ',
          step1_post_action: '.',
          step2: 'Run the installer on servers you wish to protect with a Mimic Node.',
          step3:
            'Once the node is installed, Mimic will send telemetry containing information about the server and the node will be added to your account.',
        },
      },
    },
    pagination: {
      summary: 'Showing {{start}}-{{end}} of {{total}} results',
    },
    menu: {
      activity: 'Activity',
      nodes: 'Nodes',
      configurations: 'Configurations',
      notifications: 'Notifications',
      mimicCli: 'Mimic CLI',
      download: 'Download',
      authenticate: 'Authenticate',
      members: 'Members',
      tags: 'Tags',
      logout: 'Log out',
      docs: 'Docs',
      swagger: 'Swagger',
      systemInfo: 'About this platform',
    },
    notifications: {
      title: 'Notifications',
      columns: {
        notification: 'Notification',
        urgency: 'Urgency',
        timestamp: 'Timestamp',
        channels: 'Channels',
      },
    },
    systemInfo: {
      title: 'About this platform',
      mgmtPlaneVersion: 'Management Plane version',
    },
    tags: {
      title: 'Tags',
      subtitle: 'Manage your workspace tags.',
      newTag: 'New tag',
      tags: 'Tags',
      name: 'Name',
      useCount: 'Use Count',
      confirmDelete: {
        title: 'Are you sure you want to delete the tag “{{name}}”?',
        body1:
          'This tag is currently associated with {{useCount}} nodes. Deleting it will remove the tag from the nodes, and the tag will not be available anymore.',
        body2: 'This action cannot be reversed.',
        cancel: 'No, cancel',
        ok: 'Yes, delete',
      },
      actions: {
        deleteTag: 'Delete tag',
        editTagButton: 'Edit',
      },
      tagForm: {
        nameRequired: 'Name is required.',
        nameMaxLength: 'Tag name cannot be longer than {{maxLength}} characters.',
      },
      addTag: {
        namePlaceholder: 'Name new tag...',
        submitButton: 'Add',
      },
      editTag: {
        title: 'Edit tag name',
        namePlaceholder: 'Name tag...',
        cancelButton: 'Cancel',
        submitButton: 'Save',
      },
      newTagCreated: 'New tag "{{name}}" was created',
      tagUpdated: 'Tag name was updated to "{{newName}}" from "{{oldName}}"',
      tagDeleted: 'The tag "{{name}}" was deleted and removed from {{useCount}} associated nodes.',
    },
    members: {
      title: 'Members',
      subtitle: 'Manage who has access to this workspace',
      newMember: 'New member',
      active: 'Active',
      removed: 'Removed',
      invite: {
        title: 'Add member to workspace',
        firstNamePlaceholder: 'Enter first name...',
        lastNamePlaceholder: 'Enter last name...',
        emailPlaceholder: 'Enter email...',
        permissions: 'Permissions',
        permissionsPlaceholder: 'Select Permissions',
        roleMissingError: 'Select member permissions',
        enverValidEmail: 'Enter a valid email',
        required: 'This is required',
        buttonTextUnconfirmed: 'Add member',
        buttonTextPending: 'Adding...',
        buttonTextConfirmed: 'Added!',
      },
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      roles: {
        superadmin: 'Super Admin',
        admin: 'Admin',
        editor: 'Editor',
        viewer: 'View Only',
        adminMessage: 'Full access with all permissions',
        editorMessage: 'Access with limited permissions',
        viewerMessage: 'Access with read-only permissions',
      },
      actions: {
        makeAdmin: 'Make {{user}} an admin',
        makeEditor: 'Make {{user}} an editor',
        makeViewer: 'Make {{user}} a viewer',
        remove: 'Remove this account',
        restore: 'Restore',
        update: 'Update member information',
      },
      member: 'Member',
      columns: {
        role: 'Permissions',
        dateCreated: 'Date Added',
      },
      feedback: {
        userInvited: '{{user}} was added as a member at {{email}}',
        userUpdated: '{{user}} updated',
        errorOnInvite: 'Error inviting {{user}}',
        errorOnUpdate: 'Error updating {{user}}',
      },
      update: {
        title: 'Update member information',
        buttonTextUnconfirmed: 'Update',
        buttonTextPending: 'Updating...',
        buttonTextConfirmed: 'Updated!',
      },
    },
    person: {
      name: 'Name',
      email: 'Email',
      password: 'Password',
    },
    logIn: {
      welcome: 'Welcome to Mimic',
      instructions: 'Enter your work email address to log in.',
      email: 'Enter email address...',
      continue: 'Continue',
      accountNotFound: 'Account not found',
      emailMismatch: 'There is an email mismatch with the identity provider',
      unexpectedError: 'Unexpected error',
    },
    nodes: {
      title: 'Nodes',
      failedToLoad: 'Node list failed to load',
      configured: 'Configured',
      pending: 'Pending',
      table: {
        header: {
          dateCreated: 'Created (UTC)',
          host_name: 'Host Name',
          lastSeen: 'Last seen (UTC)',
          nickname: 'Nickname',
          tags: 'Tags',
          status: 'Status',
          connectivity: 'Connectivity (UTC)',
          operationalState: 'Operational State',
          ransomwareDetected: 'Ransomware Detected',
          vigilance: 'Vigilance',
          os: 'OS',
          configuration: 'Configuration',
          configurationUpdated: 'Updated by {{updatedBy}} on {{updatedAt}} (UTC)',
          customer: 'Customer',
        },
        view_button: 'View',
        notAvailable: 'not available',
        assignConfiguration: 'Assign Configuration',
        configurationInProgress: 'Configuration in progress',
      },
    },
    configRevisions: {
      title: 'Revisions',
      table: {
        revision: 'Revision',
        author: 'Author',
        id: 'ID',
        createdAt: 'Created at (UTC)',
      },
    },
    nodeConfigurations: {
      title: 'Configurations',
      table: {
        header: {
          name: 'Name',
          assigned: 'Assigned',
          revision: 'Revision',
          tags: 'Tags',
          os: 'OS',
          lastRevised: 'Last revised (UTC)',
        },
        nameNotAvailable: 'not available',
        nodeCount: '{{nodeCount}} nodes',
        outOfDate: '{{nodeCount}} node(s) out of date',
      },
    },
    nodeConfigurationDetail: {
      title: 'Configuration details',
      notes: 'Notes',
      updatingNotesFailed: 'Failed to update notes',
      notesPlaceholder: 'Notes go here',
      assignedNodes: {
        title: 'Assigned nodes',
        assignConfigToNode: 'Assign config to node',
        table: {
          header: {
            nodes: 'Nodes',
            tags: 'Tags',
            configVersion: 'Config version',
            dateConfigRevisionApplied: 'Last updated (UTC)',
            assginedBy: 'Assigned by',
          },
        },
        noNodesAssigned: 'This config is not assigned to any nodes',
      },
      revisionHistory: {
        title: 'Revision history',
      },
      ConfigurationOverview: {
        title: 'Configuration overview',
        configurationId: 'Configuration ID',
        os: 'OS',
        latestRevision: 'Latest revision',
        lastUpdated: 'Last updated',
        windows: 'Windows',
        linux: 'Linux',
      },
    },
    node: {
      overview: 'Overview',
      node_description: 'Description',
      node_description_placeholder: 'Node description goes here...',
      addTag: 'Add tag',
      server_configuration: 'Server configuration',
      ip_address: 'IP Address',
      os: 'OS',
      nodeConfiguration: 'Node configuration',
      currentConfiguration: 'Current configuration',
      lastUpdatedBy: 'Last updated by',
      configuration: 'Configuration',
      revision: 'Revision',
      selectNewConfig: 'Select new config',
      selectNewRevision: 'Select new revision',
      notConfigured: 'This node is not configured',
      assignConfiguration: 'Assign configuration',
      node_id: 'Node ID',
      node_version: 'Node version',
      node_config_id: 'Node Config ID',
      config_file: 'Config File',
      config_file_description: 'Config File Description',
      node_state: 'Node State',
      vigilance: 'Vigilance',
      tags: 'Tags',
      credentials: 'Credentials',
      registered: 'Registered',
      heartbeats: 'Heartbeats',
      last_configuration_heartbeat: 'Last configuration heartbeat',
      last_credentials_heartbeat: 'Last credentials heartbeat',
      unknown_heartbeat: 'Unknown',
      cert_expiration: 'Certificate expiration',
      last_cert_rotation: 'Last certificate rotation',
      last_seen: 'Last seen',
      node_status: 'Status',
      node_mode: 'Mode',
      hallmarks: 'Hallmarks',
      detections: 'Detections',
      encryptions: 'Encryptions',
      encryptions_present: 'Encryptions Present',
      deflections: 'Deflections',
      connectivity: 'Connectivity',
      activity: 'Activity',
      operationalState: 'Node operational state',
      lifecycle_events: 'Node Lifecycle Events',
      events: 'Events',
      date: 'Date',
      active: 'Active',
      inactive: 'Inactive',
      idle: 'Idle',
      back: 'Back to Nodes',
      operationalStateChanging:
        'Processing your request to change status node operational state from {{currentState}} to {{requestedState}}',
      operationalStateChangeRequested: 'Operational state change requested',
      operationalStateChangeFailed: 'Operational state failed to update',
      operationalStateChangeFailedDescription:
        'The vigilance level of this node failed to update and is still set to {{requestedState}}. Please try again. If the issue persists, contact your system administrator.',
      deflectionsActive: 'Active',
      deflectionsInactive: 'Inactive',
      feedback: {
        errorFetchingConfigRevision: 'Error fetching config revision',
        assignNodeConfigError: 'Failed to assign config revision to node',
        nodeUpdated: 'Node updated',
        updateFailed: 'Failed to update node',
      },
      connectivityState: {
        connected: 'Connected',
        disconnected: 'Disconnected',
        degraded: 'Degraded',
        unknown: 'Unknown',
      },
      connectivityStateDesc: {
        connected: 'Node connected',
        disconnected: 'Node disconnected',
        degraded: 'Node degraded',
        unknown: 'Unknown',
      },
      hallmarksTable: {
        mimicID: 'Hallmark ID',
        mimicDescription: 'Hallmark',
        occurrences: 'Occurrences',
        firstSeen: 'First Seen (UTC)',
        lastSeen: 'Last Seen (UTC)',
      },
      tagDropdown: {
        typeToAddTag: 'To add a new tag, start typing',
        addTagOption: 'Add a new tag "{{tagName}}"',
        showMore: 'Show {{count}} more...',
      },
      hostNameNotAvailable: 'not available',
      selectConfiguration: 'Select a configuration',
      selectRevision: 'Select a revision',
      viewDetails: 'View details',
      selectConfigRevisionConfirmation: {
        title: 'Config Revision',
        description: 'Are you sure you want to change the config revision for this node?',
        yes: 'Yes',
        no: 'No',
      },
      status: {
        configuringTo: 'Configuring to',
        failedToConfigure: 'Failed to configure to',
        of: 'of',
        revision: 'revision',
      },
      eventsTable: {
        appliedConfig: 'Successfully configured to',
        rejectedConfig: 'Failed to configure to',
        pendingConfig: 'Pending configuration for',
        obtainedConfig: 'Changing configuration to',
      },
    },
    activity: {
      title: 'Activity',
      noWorries: 'No nodes with hallmarks',
      allClear: 'Everything is clear.',
      unexpectedError: 'Unexpected Error',
      unexpectedErrorDescription: 'Sorry, something went wrong.',
      lastUpdated: 'Last updated',
      actions: {
        refresh: 'Refresh',
      },
      table: {
        hostName: 'Host Name',
        tags: 'Tags',
        connectivity: 'Connectivity (UTC)',
        detection: 'Detection',
        encryption: 'Encryption',
        deflections: 'Deflections',
        deflectionsActive: 'Active',
        deflectionsInactive: 'Inactive',
      },
      notAvailable: 'Not available',
    },
    cliSetup: {
      skipSetup: 'Skip Setup',
      title: 'Mimic Command Line Interface (CLI) Setup',
      failedCopy: 'Failed to copy text',
      failedToGenerate: 'Failed to generate token',
      auth: {
        title: 'CLI Authentication with Mimic',
        downloadLinkText: 'Go here to download the CLI.',
        generate: {
          button: 'Generate authentication token',
        },
        step1: {
          number: '1.',
          name: 'Check that Mimic is installed',
          instructions: 'In the terminal, enter',
          command: 'mimic -v',
          successPart1: 'If a version number is returned, e.g.',
          successExample: 'Mimic 1.0.0',
          successPart2: ', go to step 2 to authenticate.',
          failurePart1: 'If you see anything else, e.g.',
          failureExample: 'mimic: command not found',
          failurePart2: ', you need to install the CLI.',
        },
        step2: {
          number: '2.',
          name: 'Authenticate with Mimic',
          instructions: 'In the terminal, enter',
          command: 'mimic login',
          description:
            'You will be asked for your email (your Mimic login) and an authentication token, which you’ll generate with the button below.',

          example: {
            loginCommand: '$ mimic login\nEnter email: {{email}}\nEnter the authentication token: {{token}}',
          },
        },
        help: {
          description: 'To view all the commands available for the Mimic CLI, type',
          command: 'mimic -h',
        },
      },
      download: {
        title: 'Download the Mimic CLI',
        subtitle:
          'Select an option below to download and install the appropriate CLI binary for your operating system.',
        start: 'Started download for {{osName}} installer.',
        after: {
          span1: 'After installing,',
          linkText: 'go here to authenticate with Mimic',
        },
        linux: 'Linux',
        windows: 'Windows',
        mac: 'Mac',
      },
      step0: {
        title: 'Welcome to Mimic!',
        body: 'Before you continue, we suggest setting up your Mimic Command Line Interface (CLI). This will help you to perform common platform tasks from the command line.',
        start: 'Start Mimic CLI Setup',
      },
      step1: {
        stepNumber: 'Step 1.',
        stepName: 'Download the CLI',
        body: 'Select an option below to download and install the appropriate CLI binary for your operating system.',
        downloadLinux: 'Download for Linux',
        downloadWindows: 'Download for Windows',
      },
      step2: {
        stepNumber: 'Step 2.',
        stepName: 'Verify installation',
        body: 'In your terminal, verify that the Mimic CLI has been installed by entering',
        command: 'Lorem ipsum',
        commandCopied: 'Command copied to clipboard',
        body2: 'Continue to the next step when you see',
        commandSuccess: 'Lorem ipsum',
      },
      step3: {
        stepNumber: 'Step 3.',
        stepName: 'Authenticate with token',
        body: 'Generate and enter the token below to authenticate with Mimic.',
        tokenCopied: 'Token copied to clipboard',
      },
      step4: {
        title: 'Congrats, you’ve successfully set up the Mimic CLI!',
        body: 'You can access these instructions again at',
        settingsCliSetup: 'Settings > Mimic CLI',
        continue: 'Continue to Mimic',
      },
    },
    errorBoundary: {
      errorMesage: 'An error has occurred',
      notFound: 'Page not found',
      notFoundDescription: 'Sorry, this page does not exist.',
    },
    tenants: {
      errorFetchingTenants: 'There was an error fetching tenants',
    },
  },
};
