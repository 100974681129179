/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useParams } from 'react-router-dom';

import { NodeViewPage } from '@/routes/Nodes/NodeViewPage';
import { ContentContainer } from '@/components/ContentContainer';

export function NodeViewRoute() {
  const params = useParams<'id'>();

  if (!params.id) {
    return <span>Invalid node id</span>;
  }

  return (
    <ContentContainer width="100%">
      <NodeViewPage id={params.id} />
    </ContentContainer>
  );
}
