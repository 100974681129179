import { Channel } from 'phoenix';
import { useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useMessage } from '@/primitives/message';
import { useWebsocket } from './useWebsocket';
import { useMimicTranslation } from '../translation/useMimicTranslation';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function useChannel(tenantID?: string) {
  const channelID = `tenant:${tenantID}`;
  const queryClient = useQueryClient();
  useWebsocket();
  const requestedTenantID = useRef<string | undefined>(undefined);
  const channel = useRef<Channel | undefined>(undefined);
  const triggering = useRef<boolean>(false);
  const [message] = useMessage();
  const { t } = useMimicTranslation('members');
  const socket = useMgmtPlaneStateStore((state) => state.socket);

  useEffect(() => {
    if (!socket) return undefined;
    if (!channelID) return undefined;
    if (requestedTenantID.current === channelID) return undefined;
    requestedTenantID.current = channelID;

    (async () => {
      if (!socket) return;
      if (triggering.current) return;
      triggering.current = true;

      channel.current = socket.channel(channelID);
      channel.current?.join().receive('error', (err) => {
        // eslint-disable-next-line no-console
        console.warn('Websocket join error', err);
      });

      channel.current.onMessage = (event, payload) => {
        if (event === 'user:created') {
          message.success(
            t('feedback.userInvited', {
              user: `${payload.data.firstName} ${payload.data.lastName}`,
              email: `${payload.data.email}`,
            }),
          );
          queryClient.invalidateQueries({ queryKey: ['users'] });
        } else {
          // eslint-disable-next-line no-console
          console.warn('Unknown event', event, payload);
        }
        return payload;
      };

      channel.current.onError((err) => {
        // eslint-disable-next-line no-console
        console.warn('Websocket channel error', err);
      });
    })();

    return () => {
      triggering.current = false;
      if (!channel.current) return;
      channel.current.leave();
      channel.current = undefined;
    };
  }, [message, channel, socket, channelID, t, queryClient]);
}
